.recipientMenu {
    list-style: none;
    font-weight: bold;
    margin-bottom: 10px;
    position: relative;

    
    
}
.recipientMenu li {
    float: left;
    margin-right: 10px;
    position: relative;
    width:150px;
}

.menu-item{
    width:210px !important;
}

.recipientMenu div {
    
    padding: 5px;
    color: #fff;
    text-decoration: none;
}

/*--- DROPDOWN ---*/
.recipientMenu ul {
	left: -120px;
    opacity: 0;
    list-style: none;
    position: absolute;
    z-index:0;
    /*left: -5000px;*/ /* Hide off-screen when not needed (this is more accessible than display: none;) */

}
.recipientMenu ul li {
    padding-top: 1px; /* Introducing a padding between the li and the a give the illusion spaced items */
    float: none;
}
.recipientMenu li:hover ul { /* Display the dropdown on hover */
	opacity: 100;
    left: -80px; /* Bring back on-screen when needed */
    transition: visibility 0s linear 800ms, opacity 800ms;
    z-index:99999;

}

