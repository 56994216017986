.avatar-container {
    position: relative;
}
.avatar-container .after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    color: #FFF;
}
.avatar-container:hover .after {
    display: block;
    background: rgba(25, 169, 116, 0.9);
}