.iconImage {
  border-radius: 50%;
  background: black;
  padding: 3px;
  margin-left:15px;

}

.ReactModal__Overlay--after-open {
	background-color: rgba(0, 0, 0, 0.75) !important;
	z-index:10000;
	opacity: 1 !important;
}

.ReactModal__Content--after-open {
	background-color:#fafafa !important;
}


.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 500ms ease-in-out;
}


.ReactModal__Overlay--before-close{
    opacity: 0;
}