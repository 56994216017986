.ReactPasswordStrength-input {
	background-color:transparent !important;
}

.ReactPasswordStrength-strength-desc{
	width: auto !important;
}

.ReactPasswordStrength {
	border: 1px solid #000;
}