* {box-sizing: border-box}

/* Container needed to position the overlay. Adjust the width as needed */
.container {
  position: relative;
  margin:5px;
  z-index:0;
}

.container:hover {
  z-index:1000;
}

/* Make the image to responsive */
.image {
  display: block;
  width: 100%;

}

img.image:hover {
  cursor:grab !important;
}

.iconImage {
  border-radius: 50%;
  background: black;
  padding: 3px;
  margin-left:15px;



}

.iconImage:hover {

  color:black;
  background:white;



}

/* The overlay effect - lays on top of the container and over the image */
.overlay {
  position: absolute; 
  bottom: 0; 
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5); /* Black see-through */
  color: #f1f1f1; 
  width: 100%;
  transition: .5s ease;
  opacity:0;
  color: white;
  font-size: 16px;
  padding: 3px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  margin:2px;
  max-height:20%;
  white-space: nowrap;
}

.overlayTop {
  position: absolute; 
  top: 0; 
  right:0;
  
  color: #f1f1f1; 
  width: 100%;
  transition: .5s ease;
  opacity:0;
  color: white;
  font-size: 20px;
  padding: 20px;
  text-align: right;
}



/* When you mouse over the container, fade in the overlay title */
.container:hover .overlay {
  opacity: 1;
}

.container:hover .overlayTop {
  opacity: 1;
}
/*.rmp-popup{
  left: 50% !important;
  margin-left:-150px !important;
}

.rmp-container{

}*/
